<template>
	<div class="login">
		<div class="login-box">
			<div class="login-title">{{ titleValue[type] }}</div>
			<el-form ref="formLogin" :model="formLogin" label-width="80px">
				<el-form-item label="手机号">
					<el-input placeholder="请输入手机号" v-model="formLogin.phoneNumber"></el-input>
				</el-form-item>
				<el-form-item label="验证码" v-show="type != 'login'">
					<el-input placeholder="发送验证码" v-model="formLogin.code">
						<el-button
							type="text"
							slot="suffix"
							style="color: #ff3465; margin-right: 12px"
							@click="getCode()"
							>{{ codeText }}</el-button
						>
					</el-input>
				</el-form-item>
				<el-form-item label="新密码" v-show="type == 'register'">
					<el-input type="password" placeholder="请输入新密码" v-model="formLogin.password_new"> </el-input>
				</el-form-item>
				<el-form-item label="密码" v-show="type == 'login'" style="margin-bottom: 0">
					<el-input type="password" placeholder="请输入密码" v-model="formLogin.password"> </el-input>
				</el-form-item>
			</el-form>
			<div class="form-footer" v-show="type == 'login'">
				<el-button type="text" style="color: #ff3465; margin-right: 12px" @click="switchType('register')"
					>忘记密码？</el-button
				>
			</div>
			<div class="dialog-button" @click="login()">{{ buttonValue[type] }}</div>
			<div class="dialog-text" v-show="type == 'phone'">
				<div class="dialog-text-1">未注册用户将会直接使用该手机号注册</div>
				<div class="dialog-text-2">
					登录/注册即代表您同意我们的
					<span>用户协议</span>
					和
					<span>隐私政策</span>
				</div>
			</div>

			<div class="dialog-login-button" v-show="type == 'phone'" @click="switchType('login')">
				使用密码登录
			</div>
			<div
				class="dialog-login-button"
				v-show="type == 'login' || type == 'register'"
				@click="switchType('phone')"
			>
				使用手机验证码登录
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			closeIcon: require("@assets/search/close.png"),
			visible: false,
			avatarImage: require("@assets/avatar.png"),
			outImage: require("@assets/search/out.png"),
			centerImage: require("@assets/search/center.png"),
			searchPlaceHolder: "复仇者联盟",
			searchVal: "",
			searchWidth: 0,

			dialogTitle: "开始登录/注册",
			dialogVisible: false,
			formLogin: {
				phoneNumber: "",
				code: "",
				password: "",
				password_new: "",
			},
			// phone login register

			buttonValue: {
				phone: "登录 / 注册",
				login: "登录",
				register: "修改密码并返回登录",
			},
			titleValue: {
				phone: "开始登录/注册",
				login: "登录",
				register: "找回密码",
			},

			codeText: "输入验证码",
			timer: null,
			timerNumber: 60,
			loginLoading: false,
			historyList: [],
			type: "phone",
		}
	},
	mounted() {
		console.log(this.isLogin)
		if (this.isLogin || !Object.values(this.$route.params).length) {
			this.$message.warning("无权限进入此页面，请重新再试。")
			this.$router.push("/")
		} else {
			const code = this.$route.params.code
			if (code === -1) {
				this.$message.warning("当前登录已过期，请重新登录。")
			} else if (code === -2) {
				this.$message.warning("当前页面未获得权限，请登录后查看。")
			}
		}
	},
	computed: {
		isLogin() {
			console.log(this.$store.state.userData)
			return this.$store.state.userData && this.$store.state.userData.token
		},
		userData() {
			return this.$store.state.userData
		},
	},
	methods: {
		reset(type = "phone") {
			this.type = type
			this.formLogin = {
				phoneNumber: "",
				code: "",
				password: "",
				password_new: "",
			}
		},
		switchType(type) {
			this.type = type
		},
		go(route) {
			this.$router.push(route)
		},
		async getCode() {
			console.log(this.formLogin.phoneNumber)
			if (!this.formLogin.phoneNumber) {
				this.$message.warning("请输入完整手机号获取验证码。")
				return
			}
			if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(this.formLogin.phoneNumber)) {
				this.$message.warning("手机号有误，请重填")
				return
			}
			if (this.timerNumber != 60) return
			let result
			if (this.type == "register") {
				result = await this.api.sendCodeForForget({
					mobile: this.formLogin.phoneNumber,
				})
			} else {
				result = await this.api.sendCode({
					mobile: this.formLogin.phoneNumber,
				})
			}
			console.log(result)
			if (result.code === 1) {
				this.$message.success(result.msg)

				this.timer = setInterval(() => {
					if (this.timerNumber < 0) {
						this.codeText = "输入验证码"
						this.timerNumber = 3
						clearInterval(this.timer)
					} else {
						this.codeText = String(this.timerNumber) + "秒后获取"
						this.timerNumber = this.timerNumber - 1
					}
				}, 1000)
			} else {
				this.$message.error(result.msg)
			}
		},
		async login() {
			let type = this.type
			if (type == "phone") {
				if (!this.formLogin.phoneNumber) {
					this.$message.warning("请输入完整手机号后登录。")
					return
				}
				if (!this.formLogin.code) {
					this.$message.warning("请输入验证码后登录。")
					return
				}
				this.loginLoading = true
				const result = await this.api.loginByCode({
					mobile: this.formLogin.phoneNumber,
					code: this.formLogin.code,
					deviceInfo: {
						deviceName: "ok",
						deviceUid: "2323",
						platform: "3223",
						location: "dasdd",
					},
				})
				if (result.code === 1) {
					this.$message.success("登录成功")
					this.dialogVisible = false
					this.$store.commit("setUserData", result.data)
					this.reset()
					this.jump()
				} else {
					this.$message.error(result.msg)
				}
				console.log(result)
			} else if (type == "login") {
				if (!this.formLogin.phoneNumber) {
					this.$message.warning("请输入完整手机号后登录。")
					return
				}
				if (!this.formLogin.password) {
					this.$message.warning("请输入密码后登录。")
					return
				}
				this.loginLoading = true
				const result = await this.api.loginByPassword({
					mobile: this.formLogin.phoneNumber,
					password: this.formLogin.password,
          deviceInfo: {
            platform: "web",
            deviceName: "DESKTOP-GLG59MG",
            deviceUid: "magna esse deserunt id proident",
            location: "", // 后端填充
            appVersion: "无",
            network: "有线",
            ISP: "",  // 后端填充
            deviceBrand: "兼容机",
            deviceMode: "无",
            screenHeight: 1080,
            screenWidth: 1920
          }
				})
				if (result.code === 1) {
					this.$message.success("登录成功")
					this.dialogVisible = false
					this.$store.commit("setUserData", result.data)
					this.reset()
					this.jump()
				} else {
					this.$message.error(result.msg)
				}
			} else if (type == "register") {
				if (!this.formLogin.phoneNumber) {
					this.$message.warning("请输入完整手机号后修改密码。")
					return
				}
				if (!this.formLogin.code) {
					this.$message.warning("请输入验证码后修改密码。")
					return
				}
				if (!this.formLogin.password_new) {
					this.$message.warning("请输入新密码后修改密码。")
					return
				}
				this.loginLoading = true
				const result = await this.api.editPassword({
					mobile: this.formLogin.phoneNumber,
					newPassword: this.formLogin.password_new,
					code: this.formLogin.code,
				})
				if (result.code == 1) {
					this.$message.success("修改成功")
					this.reset("login")
				} else {
					this.$message.error(result.msg)
				}
			}
			this.loginLoading = false
		},
		jump() {
			this.$router.push({
				name: this.routerFromObject.name,
				query: Object.keys(this.routerFromObject.query).length ? this.routerFromObject.query : {},
			})
		},
		logOut() {
			let userData = {
				token: "",
				userInfo: {
					avatar: "",
					createdAt: "",
					gender: "",
					mobile: "",
					nickname: "",
					signature: "",
				},
				memberPlan: {
					expiresAt: "",
					name: "",
				},
			}
			this.reset()
			localStorage.removeItem("userData")
			this.$store.commit("setUserData", userData)
			this.$message.success("登出成功！")
		},

		resetSearch() {
			this.historyList = []
			localStorage.removeItem("BIYOUHISTORY")
		},
		getHistory() {
			let list = localStorage.getItem("BIYOUHISTORY")
			if (list) {
				this.historyList = JSON.parse(list)
			}
		},
		search() {
			console.log(this.searchVal)
			this.historyList.push(this.searchVal)
			localStorage.setItem("BIYOUHISTORY", JSON.stringify(this.historyList))
			this.visible = false
			document.querySelector(".search-input input").blur()
			this.searchItem(this.searchVal)
		},
		removeHistory(index) {
			this.historyList.splice(index, 1)
			localStorage.setItem("BIYOUHISTORY", JSON.stringify(this.historyList))
		},
	},
}
</script>

<style lang="scss">
.login {
	width: 100%;
	height: 100%;
	background: rgb(18, 20, 46);
	display: flex;
	flex-direction: column;
	align-items: center;
	.login-title {
		font-size: 18px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 25px;
		color: #000000;
		margin-bottom: 30px;
	}
	.login-box {
		margin-top: 180px;
		// height: 320px;
		background: white;
		padding: 20px;
		border-radius: 10px;
		width: 460px;
	}
}
.center-main {
	position: relative;
	.avatar-setting {
		position: absolute;
		top: 275px;
		/* right: 0; */
		left: 530px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.avatar-setting-img {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.avatar-setting-button {
			width: 80px;
			height: 35px;
			background: #ff3465;
			opacity: 1;
			border-radius: 5px;
			display: flex;
			justify-content: center;
			align-items: center;

			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #ffffff;
			margin-top: 20px;
			user-select: none;
			cursor: pointer;
		}
	}
}
.header-search {
	padding-top: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.search-input {
		input {
			border: 1px solid rgba(255, 255, 255, 0.1);
			background: rgba(255, 255, 255, 0.1);
			height: 70px;
		}
	}
	.search-right {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		margin-left: 12px;
		.right-subscribe {
			width: 120px;
			height: 50px;
			border: 2px solid rgba(255, 255, 255, 0.5019607843137255);
			opacity: 1;
			border-radius: 5px;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #ffffff;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.right-subscribe:hover {
			cursor: pointer;
			border-color: #ff3465;
			color: #ff3465;
		}
		.right-avatar {
			width: 70px;
			height: 70px;
			border-radius: 50%;
			overflow: hidden;
			margin-left: 40px;
			cursor: pointer;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
}
.hover-box {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	.hover-item {
		height: 60px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding-left: 20px;
		img {
			width: 30px;
			height: 30px;
		}
		span {
			font-size: 15px;
			font-family: PingFang SC;
			font-weight: 400;
			line-height: 21px;
			color: #797a8e;
			user-select: none;
			cursor: pointer;
		}
	}
	.hover-item:hover {
		span {
			color: #ff3465;
		}
	}
}
.el-dialog {
	border-radius: 10px !important;
}

.login {
	.el-dialog__body {
		padding: 20px;
	}
	.el-input__inner {
		background-color: #f1f1f3;
		color: #aaaaaa;
		border: none !important;
	}
	.el-form-item__label {
		font-size: 15px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #666666;
	}
	.dialog-button {
		height: 60px;
		background: #ff3465;
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		font-family: PingFang SC;
		font-weight: 400;
		color: #ffffff;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}
	.dialog-text {
		margin-top: 20px;
		.dialog-text-1 {
			text-align: center;
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #ff3465;
			user-select: none;
		}
		.dialog-text-2 {
			font-size: 14px;
			font-family: PingFang SC;
			font-weight: 500;
			line-height: 20px;
			color: #999999;
			text-align: center;
			margin-top: 10px;
			span {
				font-size: 14px;
				font-family: PingFang SC;
				font-weight: 500;
				line-height: 20px;
				color: #ff3465;
				margin: 0 5px;
				user-select: none;
				cursor: pointer;
			}
		}
	}
	.dialog-login-button {
		text-align: center;

		font-size: 14px;
		font-family: PingFang SC;
		font-weight: 500;
		line-height: 20px;
		color: #ff3465;
		cursor: pointer;
		user-select: none;
		margin-top: 40px;
	}
	.form-footer {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
}

.tip {
	font-size: 13px;
	font-family: PingFang SC;
	font-weight: 500;
	line-height: 18px;
	color: #797a8e;
	margin-top: 8px;
}

.search-history {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: wrap;
	.history-item-box {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		flex-wrap: wrap;
		.history-item {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 2px 20px;
			background: #f1f1f1;
			margin-right: 10px;
			margin-bottom: 10px;
			border-radius: 5px;
			cursor: pointer;
			img {
				width: 40px;
				height: 40px;
			}
		}
	}

	.search-history-reset {
		width: 100%;
		text-align: right;
		font-size: 13px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 18px;
		color: #ff3465;
		margin-top: 24px;
		cursor: pointer;
	}
}
</style>
